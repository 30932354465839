/** 关于我们 */
<template>
  <div class="about-us">
    <!-- 公司介绍 -->
    <section class="company-con back-fff mb-20" ref="companyCon" v-for="(item,index) in company" :key="index">
      <div class="content">
        <div style="padding:0 10px">
          <el-image class="company-logo" :src="item.img"></el-image>
          <h2 class="company-title IPBS-subtitle">{{item.name}}</h2>
          <p class="IPBS-info text-ellipsis-lines">{{item.info}}</p>
          <el-button type="text" icon="el-icon-arrow-down" class="look-more-down" @click="moreDown($event,index)">查看更多
          </el-button>
        </div>
      </div>
    </section>
    <!-- 研究团队 -->
    <section class="content back-fff border-r8 mb-20">
      <div class="aboutUs-con aboutUs-team">
        <h2 class="aboutUs-title">研究团队</h2>
        <ul class="aboutUs-list team-list">
          <li class="team-item team-item-first mb-30">
            <div class="flex">
              <el-image class="team-item-img" :src="require('@/assets/images/aboutUs-zbs.png')" fit="contain">
              </el-image>
              <div :class="['team-item-con', {'flex-1':$isPc}, 'ml-20']">
                <div class="team-item-achieve pa-30 border-r4">
                  <p><strong>钟基立</strong>博士是美国MIT斯隆学者，UCLA法律博士，北大法学博士，台大企管硕士、化学工程学士，以及MIT科技管理硕士。具有美国律师，中国律师，专利代理人以及证券从业资格。现任厦门大学兼职硕导、华东政法大学特聘研究员。</p>
                </div>
                <h2 v-if="$isPc" class="team-item-name">
                  钟基立博士，以华尔街和MIT创新经验，创立“智汇权·IP道”，为IP开发者设计新商模，吸引IP消费者共创价值、共享收益，助力IP开发者降低风险，提高回报率，增加IP金融的应用场景。已协助全球农业、文旅、法律、中医以及时尚等领域前瞻者，实现弯道超车。
                </h2>
              </div>
            </div>
          </li>
          <li class="team-item mb-30" v-for="(item,index) in teams" :key="index">
            <div class="flex">
              <div :class="['team-item-con', {'flex-1':$isPc}]">
                <h2 class="team-item-name mb-20">{{item.name}}</h2>
                <div class="team-item-achieve pa-30 border-r4">
                  <p>{{item.achieve}}</p>
                </div>
              </div>
              <el-image class="team-item-img ml-20" :src="item.img" fit="contain"> </el-image>
            </div>
            <p class="team-item-info IPBS-info"> {{item.info}}</p>
          </li>
        </ul>
      </div>

    </section>
    <!-- 调研小组 -->
    <section class="content back-fff border-r8">
      <div class="aboutUs-con aboutUs-group">
        <h2 class="aboutUs-title">研究团队</h2>
        <ul class="aboutUs-list group-list flex-wrap justify-between">
          <li class="group-item mb-20 flex" v-for="(item,index) in group" :key="index">
            <el-image class="group-item-img border-r4" :src="item.img"></el-image>
            <div class="group-item-info flex-1">
              <h4 class="group-item-name">{{item.name}}</h4>
              <p class="group-item-text IPBS-info"> {{item.info}}</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'AboutUs',
  data () {
    return {
      company: [
        {
          name: '北京智慧财富知识产权金融研究院', img: `${require('@/assets/images/aboutUs-img1.png')}`, info: '北京智慧财富知识产权金融研究院成立于2019年12月，是由北京市知识产权局主管、北京市民政局登记注册的民办非企业单位。是全国首家专门从事知识产权金融研究的民非组织。2020年中关村论坛上，国家知识产权局雷筱云司长、中关村发展集团赵长山董事长、北京市知识产权局杨东起书记、中关村管委会朱建红副主任共同为研究院成立揭牌。研究院将面向国家知识产权运营体系建设的需要，围绕知识产权质押融资、证券化、融资租赁、信托、投资基金等金融创新模式进行研究、试点、推广，建立知识产权金融新模式和创新服务体系，推动知识产权金融普遍化、常态化、规模化发展，做新时代知识产权金融高端智库平台。'
        },
        { name: '北京中金浩资产评估有限责任公司', img: `${require('@/assets/images/aboutUs-img2.png')}`, info: '北京中金浩资产评估有限责任公司成立于2006年，是国内最大的知识产权评估机构、知识产权质押融资与知识产权证券化服务机构。中金浩通过搭建知识产权金融创新服务体系，与各地政府、金融机构广泛合作，推动落地了北京智融宝、深圳科创贷、厦门知担贷、南京知贷宝、江西智享贷、天津华智宝等知识产权质押融资产品。每年，中金浩服务企业知识产权融资规模超过100亿元。自国务院发布相关政策相继鼓励海南自贸区、粤港澳大湾区、雄安新区等探索知识产权证券化以来，中金浩深入研究学习国外成功案例，积极参与国内知识产权证券化实践。截止2021年10月31日，中金浩在全国范围内参与、推动落地知识产权证券化产品36单（其中2019年及以前7单，2020年11单，2021年前10个月18单），占全国总量47单的76.60%，合计发行规模111.81亿元，占全国总量138.03亿元的81.00%。作为项目管理人全面协调推动广州、佛山、南京、珠海、台州、泉州、天津滨海新区、天津东丽区等地知识产权证券化项目。同时，积极服务国内知识产权类证券化金融探索，典型案例包括安徽省国元信托知识产权信托交易试点项目、大唐雷音寺-短视频版权媒体资产托管项目等等。' }
      ],
      teams: [
        { name: '丁坚', img: `${require('@/assets/images/aboutUs-dj.png')}`, achieve: '从事知识产权评估质押融资近二十年,提出知识产权质押融资产品化解决方案,每年帮助中小企业知识产权融资超过一百亿。推动知识产权证券化、知识产权融资租赁、知识产权信托等知识产权金融创新模式发展，连读多年被评为“资产评估行业优秀共产党员”。', info: '北京智慧财富集团、中金浩董事长,知识产权金融研究院院长，中共党员，资产评估师，知识产权评估运营专家，国家知识产权运营公共服务平台专家库金融业务类专家，北京国际版权交易中心版权评估工作委员会专家，北京市知识产权服务领军人才，北京资产评估协会维权委员会会员，北京市知识产权局专家库入库专家、广东省知识产权价值评价专家库入库专家、山东省知识产权公共服务平台智库入库专家、海南省知识产权局专家库第一批入库专家、安徽省知识产权专家库入库专家、太原市知识产权专家库入库专家，2017年度中国知识产权领域十大最具影响力人物。' },
        { name: '马新明', img: `${require('@/assets/images/aboutUs-mxm.png')}`, achieve: '长期从事无形资产的评估与研究,致力于解决各类无形资产的估值难题,服务于企业知识产权运营尤其是知识产权质押融资新渠道的建设,探索技术与资本的有机结合。参与编写《高价值专利培育与评估》，曾在《中国知识产权报》、《中国科技信息》、《中华商标》等报刊发表多篇专业文章。', info: '北京智慧财富集团技术总监、中金浩总经理,资产评估师、房地产估价师、专利分析师、知识产权评估专家，中华商标协会个人理事，北京市知识产权服务领军人才，首都知识产权服务业协会商业秘密专业委员会，东城区体育馆路街道新的社会阶层人士联谊会理事会理事，北京市知识产权局专家库入库专家、广东省知识产权价值评价专家库入库专家、海南省知识产权局专家库入库专家、昆明市知识产权专家库入库专家、上海市科技成果评价研究院科技评价专家库入库专家、成都市知识产权智库维权援助专家。' },
        { name: '刘凯达', img: `${require('@/assets/images/aboutUs-lkd.png')}`, achieve: '具有知识产权、金融的跨领域工作背景，积极参与知识产权运营与金融产品的探索与结合，具备覆盖知识产权全生命周期的服务经验，擅长知识产权布局、确权、运营、交易、金融等多维度综合策划。', info: '北京智慧财富集团金融事业部总经理、广东智慧财富知识产权运营有限公司总经理、北京中金浩资产评估有限责任公司大湾区事业部总经理，广东省侨界海外留学归国人员协会副秘书长，深圳南山知识产权联盟第一届理事会金融委员会委员。' },
      ],
      group: [
        { name: '侯剑', img: `${require('@/assets/images/aboutUs-hj.png')}`, info: '华东政法大学法律硕士，主修知识产权实务。通过法律职业资格考试和证券从业资格考试。曾获得上海市模拟法庭一等奖、文献综述竞赛一等奖等，曾实习于大同市人民检察院、北京金诚同达（上海）律师事务所、上海方旭律师事务所。' },
        { name: '陈祺', img: `${require('@/assets/images/aboutUs-cq.png')}`, info: '本科毕业于华东政法大学知识产权专业，现为华东政法大学知识产权专业研究生，主攻知识产权法领域，擅长文献检索与翻译，曾实习于北京金诚同达律师事务所（上海分所）、上海锦天城律师事务所、上海市普陀区基层人民法院。' },
        { name: '张秋苹', img: `${require('@/assets/images/aboutUs-zqp.png')}`, info: 'IP流程管理专家，熟悉国内外专利、商标、版权申请诀窍，熟悉IP组合布局规划。熟悉社群媒体运营，实操在全球主流社群媒体平台的内容制作、投放与运维。曾就职苹果供应链中创新型企业的知识产权部门，现任创新企业智汇权高管。' },
        ]
    }
  },
  methods: {
    // 公司介绍-查看更多
    moreDown (e, ind) {
      this.$refs.companyCon[ind].getElementsByClassName('IPBS-info')[0].classList.add('line-all')
      e.currentTarget.style.display = "none"
    }
  },
}
</script>
<style lang="scss" scoped>
.company-con {
  padding: 40px 0 68px;
  text-align: center;
  .company-title {
    margin: 22px 0 42px;
  }
  .IPBS-info {
    text-align: left;
    &.line-all {
      -webkit-line-clamp: initial;
    }
  }
}
// 研究团队、调研小组公共样式
.aboutUs-con {
  padding: 46px;
  .aboutUs-title {
    font-size: 34px;
  }
  .aboutUs-list {
    margin: 46px;
  }
}

// 研究团队
.aboutUs-team {
  .team-item {
    .team-item-con {
      align-self: end;
      .team-item-name {
        text-align: right;
        font-size: 28px;
        line-height: 32px;
      }
      .team-item-achieve {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        background-color: #2b62b2;
        line-height: 32px;
        strong {
          font-style: italic;
          font-size: 28px;
          letter-spacing: 4px;
        }
      }
    }
    .team-item-info {
      font-size: 16px;
      margin-top: 20px;
    }
    // 团队第一个
    &.team-item-first {
      .team-item-con {
        align-self: start;
        .team-item-name {
          margin-top: 40px;
          font-size: 20px;
          text-align: left;
        }
      }
      .el-image {
        align-self: start;
      }
    }
  }
}
// 调研小组
.aboutUs-group {
  .group-item {
    width: 48%;
    .group-item-img {
      width: 135px;
      max-height: 181px;
    }
    .group-item-info {
      margin-left: 24px;
    }
  }
}
@media (max-width: 767px) {
  .company-con {
    padding: 20px 0;
    text-align: center;
    .company-title {
      margin: 22px;
    }
    .IPBS-info {
      -webkit-line-clamp: 5;
    }
  }
  // 研究团队、调研小组公共样式
  .aboutUs-con {
    padding: 10px;
    .aboutUs-title {
      font-size: 20px;
    }
    .aboutUs-list {
      margin: 10px 0;
    }
  }
  // 研究团队
  .aboutUs-team {
    .team-item {
      &:first-child .team-item-con {
        margin-left: 10px;
      }
      .team-item-con {
        width: 55%;
        align-self: start;
        .team-item-name {
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 10px;
        }
        .team-item-achieve {
          padding: 0;
          background-color: transparent;
          color: #333;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          strong {
            font-size: 18px;
          }
        }
      }
      .el-image {
        flex: 1;
        align-self: start;
      }
      .team-item-info {
        margin-top: 10px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  // 调研小组
  .aboutUs-group {
    .group-item {
      width: 100%;
      .group-item-img {
        width: 135px;
        max-height: 181px;
      }
      .group-item-info {
        margin-left: 10px;
        .IPBS-info {
          margin-top: 10px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>